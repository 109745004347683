import React from 'react';
import { useSettings } from 'yoshi-flow-editor-runtime/tpa-settings/react';
import settingsParams from '../../settingsParams';

import { st, classes } from './Tagline.st.css';

interface Props {
  description: string;
  highlighted: boolean;
}

export const Tagline: React.FC<Props> = ({ description, highlighted }) => {
  const settings = useSettings();
  const showPlanTagline = settings.get(settingsParams.showTagline);

  if (!showPlanTagline) {
    return null;
  }

  return (
    <div className={st(classes.tagline, { highlighted })}>
      <span>{description}</span>
    </div>
  );
};
